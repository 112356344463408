.main{
  z-index: 100;
  width: 90%;
  height: 90%;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .left_path{
    width: 40%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14pt;
    border-right: 1px solid #CCC;
    .otdesc{
      display: flex;
      align-items: flex-end;
      margin-bottom: 10px;
      .ot{
        width: 32px;
        height: 32px;
        border: 5px solid #CCC;
        position: relative;
        cursor: pointer;
        margin-top: -0px;
        .otenter{
          width: 100%;
          height: 100%;
          background-color: #007aff;
          position: absolute;
          top: -10px;
          left: 0;
          transition: .2s;
        }
      }
      .desc_ot{
        font-weight: 400;
        margin-left: 20px;
        font-size: 12pt;
      }
    }

    div{
      font-weight: 600;
      margin-top: 10px;
    }
    .title{
      font-size: 18pt;
      border-bottom: 1px solid #CCC;
    }
    .name{}
    .time{}
    .price{
    }
    .price_strock{
      width: calc(100% - 0px);
      display: flex;
      flex-direction: column;
    }
    .priory{}
    input{
      width: calc(100% - 40px);
      height: 40px;
      padding: 0 10px;
      outline: none;
      border: 1px solid #CCC;
    }
    textarea{
      width: calc(100% - 40px);
      height: 180px;
      outline: none;
      border: 1px solid #CCC;
      padding: 10px;
      resize: none;
    }
  }
  .right_path{
    width: calc(40% - 20px);
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 20px;
    font-size: 14pt;

    .linepoint{
      width: 100%;
      height: 50px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      padding: 5px 0;
      border-bottom: 1px solid #CCC;
      .namepoint{}
      input{
        height: calc(100% - 10px);
        width: 200px;
        padding: 0  10px;
        border: 1px solid #CCC;
        outline: none;
      }
    }


    .title{
      font-weight: 600;
      margin-top: 32px;
      margin-bottom: 20px;
    }
    .desc{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .desc_plus{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 20px;
        input{
          width: calc(100% - 220px);
          height: 40px;
          padding: 0 10px;
          outline: none;
          border: 1px solid #CCC;
        }
        select{
          width: calc(100% - 220px);
          height: 40px;
          padding: 0 10px;
          outline: none;
          border: 1px solid #CCC;
        }
        .plusdesc_btn{
          width: 186px;
          height: 40px;
          border: 1px solid #CCC;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 20px;
          transition: 0.2s;
          cursor: pointer;
        }
        .plusdesc_btn:hover{
          background-color: #454545;
          color: #FFF;
          transition: 0.2s;
        }
      }
      .list{

        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        align-items: flex-start;
        margin-top: -15px;
        .line{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 5px 10px;
          background-color: #5ac8fa;
          margin-top: 5px;
          margin-right: 10px;
          font-size: 10pt;
          i{
            color: #FFF;
            cursor: pointer;
            transition: 0.2s;
            margin-right: 10px;
          }
          i:hover{
            color: #454545;
            transition: 0.2s;
          }
        }
      }

    }

    .discount{
      width: 100%;
      .discount_name{
        margin: 10px 0 0 0;
      }
      .discount_form{
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .percent{
          width: 150px;
          height: 30px;
          border: 1px solid #CCC;
          outline: none;
          text-align: center;
          margin: 5px 0 5px 0;
        }
        .descpercent{
          width: calc(100% - 184px);
          height: 30px;
          border: 1px solid #CCC;
          outline: none;
          padding: 0 10px;
          margin: 5px 0 5px 10px;
        }
        .btn{
          width: 186px;
          height: 40px;
          border: 1px solid #CCC;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 0px;
          transition: 0.2s;
          cursor: pointer;
          font-size: 10pt;
        }
        .btn:hover{
          background-color: #454545;
          color: #FFF;
          transition: 0.2s;
        }
      }
      .listdiscount{
        width: calc(100% - 80px);
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        .line{
          display: flex;
          width: 100%;
          font-size: 10pt;
          border-bottom: 1px solid #CCC;
          margin-top: 5px;
        }
        .percent{
          width: 80px;
        }
        .desc{
          width: calc(100% - 120px);
        }
        .tools{
          width: 30px;
          i{
            cursor: pointer;
          }
          i:hover{
            color: #CCC;
          }
        }
      }

    }
  }
  .line_btn{
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 20px 40px;
    border: 1px solid #CCC;
    cursor: pointer;
    transition: 0.2s;
  }

  .line_btn:hover{
    background-color: #454545;
    color: #FFF;
    transition: 0.2s;
  }
}