.main{
  width: 90%;
  height: 90%;
  background-color: #FFFFFF;
  z-index: 100;
  padding: 20px;
  display: flex;
  flex-direction: column;
  color: #454545;
  .title{
    font-size: 20pt;
    font-weight: 800;
    height: 50px;
  }
  .standart{
    display: flex;
    width: 100%;
    .name{
      max-width: 700px;
      width: 100%;
      height: 50px;
      border: none;
      border-bottom: 1px solid #454545;
      outline: none;
      font-size: 16pt;
      margin-right: 20px;
    }
    select{
      border: 1px solid #454545;
      width: 250px;
      padding: 0 10px;
      outline: none;
    }
  }


  .columns{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: calc(100% - 20px);
    height: calc(100% - 50px - 50px - 20px - 70px) ;
    .tab{
      height: 100%;
      width: calc(100% / 4 - 10px);
      .titletab{
        font-size: 14pt;
        font-weight: 600;
      }
      .name{
        border: 1px solid #454545;
        padding: 0 10px;
        font-size: 10pt;
        width: calc(100% - 20px);
        margin-top: 10px;
        height: 40px;
      }
      .btntab{
        height: 30px;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
        border: 1px solid #CCC;
        background-color: #F1F1F1;
        transition: 0.2s;
        cursor: pointer;
      }
      .btntab:hover{
        color: #FFF;
        background-color: #410099;
        border: 1px solid #410099;
        transition: 0.2s;
      }
      .points{
        display: flex;
        flex-direction: column;
        border: 1px solid #CCC;
        padding: 10px;
        height: calc(100% - 30px - 20px - 122px);
        overflow-y: auto  ;
        .point{
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          font-size: 10pt;
          border-bottom: 1px solid #CCC;
          padding: 5px 0;
          .coma{
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #6e037b;
            margin-top: 4px;
            cursor: grab;
          }
          .coma:active{
            cursor: grabbing;
          }
          textarea{
            border: 1px solid #CCC;
            border-radius: 0;
            min-height: content-box;
            resize: block;
            outline: none;
            padding: 5px 10px;
          }
          .text{
            width: calc(100% - 65px);

          }
          .trash{
            width: 20px;
            height: 20px;
            i{
              color: #454545;
              cursor: pointer;
              transition: 0.2s;
            }
            i:hover{
              color: #ee3b71;
              transition: 0.2s;
            }
          }
        }

      }
    }
  }
  .btns{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .emailtitle{
      font-size: 14pt;
      font-weight: 600;
    }
    .email{
      width: 300px;
      height: 41px;
      border: none;
      border-bottom: 1px solid #454545;
      outline: none;
      margin-right: 30px;
      padding: 0 10px;
    }
    .btn{
      width: 120px;
      height: 40px;
      border: 1px solid #454545;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      cursor: pointer;
    }
    .btn:hover{
      background-color: #454545;
      color: #FFF;
      transition: .2s;
    }
  }
}