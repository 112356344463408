.main{
  z-index: 100;
  width: calc(90% - 60px);
  height: calc(90% - 60px);
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;

  .mycities{
    width: calc(50% - 50px);
    height: calc(100% - 40px);
    margin-right: 10px;
    padding: 20px;
    border: 1px solid #CCC;
    .title{
      font-size: 22pt;
      font-weight: 600;
      margin-bottom: 20px;
      border-bottom: 1px solid #CCC;
    }
    .list{
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      height: calc(100% - 60px);
      .cityname{
        margin-bottom: 10px;
      }
    }

  }

  .newcity{
    width: calc(50% - 50px);
    height: calc(100% - 40px);
    margin-left: 10px;
    padding: 20px;
    .title{
      font-size: 22pt;
      font-weight: 600;
      margin-bottom: 20px;
      border-bottom: 1px solid #CCC;
    }
    input{
      width: calc(100% - 190px);
      height: 50px;
      padding: 0 10px;
      border: 1px solid #CCC;
      outline: none;
    }
    input:focus{
      border: 1px solid #CCC;
      border-bottom: 1px solid #410099;
    }
    .btn{
      width: 188px;
      height: 50px;
      padding: 0 10px;
      border: 1px solid #CCC;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
      cursor: pointer;
      transition: .2s;
    }

    .btn:hover{
      transition: .2s;
      background-color: #454545;
      color: #FFFFFF;
    }
  }

}