.bodymain{
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  background-color: #E1E1E1;
  .main{
    width: 100%;
    max-width: 1920px;
    height: calc(100% - 80px);
    display: flex;

  }
  .leftpath{
    width: 300px;
    height: 100%;
    margin-right: 20px;
  }
  .centerpath{
    width: calc(100% - 420px);
  }
  .rightpath{
    width: 80px;
    margin-left: 20px;
  }
}