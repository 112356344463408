.main{
  width: calc(90% - 60px);
  height: calc(90% - 60px);
  background-color: #FFF;
  padding: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 10;
  .left{
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .nameblock{
      width: 100%;
      font-size: 16pt;
      //font-weight: 600;
      color: #454545;
      margin-top: 20px;
    }
    .photo{
      width: calc(80px * 4);
      height: calc(80px * 4);
      background-size: cover;
      background-position: center center;
      border: 1px solid #CCC;
      cursor: pointer;
      .active{
        width: 100%;
        height: 100%;
        display: none;
        justify-content: center;
        align-items: center;
        background-color: rgba(255,255,255,0.7);
        i{
          font-size: 40pt;
          color: #410099;
        }
      }
    }
    .photo:hover{
      border: 1px solid #410099;
      .active{
        display: flex;
        i{

        }
      }
    }
    .title{
      width: calc(100% - 20px);
      height: 60px;
      padding: 0 10px;
      margin-top: 10px;
      border: 1px solid #CCC;
      outline: none;
    }
    .desc{
      width: calc(100% - 0px);
      height: 68px;
      padding: 0 10px;
      margin-top: 10px;
      border: 1px solid #CCC;
      outline: none;
      resize: none;
    }
    .paramcoin{
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      .paramcoin_column{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .paramcoin_column_label{
          margin-bottom: 10px;
        }
        .paramcoin_column_input{
          border: 1px solid #CCC;
          width: calc(50% - 10px);
          height: 40px;
          outline: none;
          text-align: center;
        }
      }

    }

    .dateblock{
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      label{
        width: 150px;
      }
    }
  }
  .center{
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .nameblock{
      width: 100%;
    }
    .desc{
      width: calc(100% - 20px);
      height: 40px;
      padding: 0 10px;
      margin-top: 10px;
      border: 1px solid #CCC;
      outline: none;
      resize: none;
    }
    .btnplus{
      width: 200px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #454545;
      font-size: 10pt;
      margin-top: 10px;
      cursor: pointer;
      transition: 0.2s;
    }
    .btnplus:hover{
      color: #FFF;
      border: 1px solid #410099;
      background-color: #410099;
      transition: 0.2s;
    }
    .listp{
      width: calc(100% - 40px);
      height: calc(100% - 50px);
      overflow-y: auto;
      padding: 20px;
      margin-top: 10px;
      border: 1px solid #CCC;
      .strock{
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin: 10px 0;
        position: relative;
        z-index: 10;
        p{
          width: calc(100% - 70px);
          text-align: justify;
          cursor: pointer;
          padding: 10px;
          font-size: 10pt;
          .grab{
            width: calc(100% - 50px);
            height: calc(100% );
            background-color: rgba(255,255,255,0.7);
            border: 1px solid #410099;
            position: absolute;
            left: 0px;
            top: 0px;
            display: none;
            justify-content: center;
            align-items: center;
            i{
              font-size: 30pt;
            }
          }
        }
        p:hover{

          .grab{
            display: flex;
          }
        }
        .delete{
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: flex-start;
          cursor: pointer;
          i{
            font-size: 16pt;
            color: #CCC;
          }
          i:hover{
            color: #454545;
          }
        }


      }


    }

  }
  .right{
    width: 20%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    .up{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      .title{}
      .listcom{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-y: auto;
        width: 100%;
        .com{
          width: calc(100% - 20px);
          padding: 10px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-bottom: 1px solid #CCC;
          .name{
            font-family: 'ALS';
            font-size: 14pt;
            font-weight: 800;
            width: calc(100% - 80px);
          }
          .btnoff{
            width: 50px;
            height: 20px;
            border: 3px solid #454545;
            position: relative;
            border-radius: 5px;
            cursor: pointer;
            .sphere{
              width: 20px;
              height: 16px;
              background-color: #454545;
              position: absolute;
              top: 2px;
              left: 3px;
              border-radius: 5px;
            }
          }
          .btnon{
            width: 50px;
            height: 20px;
            border: 3px solid #410099;
            position: relative;
            border-radius: 5px;
            cursor: pointer;
            .sphere{
              width: 20px;
              height: 16px;
              background-color: #410099;
              position: absolute;
              top: 2px;
              right: 3px;
              border-radius: 5px;
            }
          }
        }
      }
    }
    .bottom{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      .saving{
        width: calc(100% - 2px);
        height: 60px;
        border: 1px solid #CCC;
        display: flex;
        justify-content: space-around;
        align-items: center;
        transition: 0.3s;
        cursor: pointer;
        i{
          font-size: 20pt;
          color: #CCC;
          transition: 0.3s;
        }
        .text{
          text-transform: uppercase;
          margin-left: 20px;
        }
      }
      .saving:hover{
        transition: 0.3s;
        i{
          color: #410099;
          transition: 0.3s;
        }
        border: 1px solid #410099;
      }

    }

  }
  .savebtn{
    width: 120px;
    height: 50px;
    border: 1px solid #FFF;
    background-color: rgba(0,0,0,0);
    color: #FFF;
    position: absolute;
    bottom: 10px;
    left: 10px;
    z-index: 1000;
    border-radius: 0;
  }
  .cancelbtn{
    width: 120px;
    height: 50px;
    border: 1px solid #FFF;
    background-color: rgba(0,0,0,0);
    color: #FFF;
    position: absolute;
    bottom: 10px;
    left: 150px;
    z-index: 1000;
    border-radius: 0;
  }
  .stepbtn{
    //width: 120px;
    //height: 50px;
    //border: 1px solid #FFF;
    //background-color: rgba(0,0,0,0);
    color: #FFF;
    position: absolute;
    bottom: 10px;
    left: 300px;
    z-index: 1000;
    //border-radius: 0;
  }
}