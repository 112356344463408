.bodymain{
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  background-color: #E1E1E1;
  .main{
    width: 100%;
    max-width: 1920px;
    height: calc(100% - 80px);
    display: flex;

  }
  .leftpath{
    width: 300px;
    height: 100%;
    margin-right: 20px;
  }
  .centerpath{
    width: calc(100% - 320px);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    .nav{
      display: flex;
      .back{
        width: 250px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 40px;
        border: 1px solid #CCC;
        margin: 20px 10px 20px 0px;
        transition: 0.3s;
        cursor: pointer;
        color: #454545;
        i{
          font-size: 20pt;
          color: #CCC;
          transition: 0.3s;
        }
        .namebtn{

        }
      }
      .back:hover{
        border: 1px solid #454545;
        transition: 0.3s;
        i{
          color: #410099;
          transition: 0.3s;
        }
      }
    }
    .pluspost{
      width: 250px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 40px;
      border: 1px solid #CCC;
      margin: 20px 0;
      transition: 0.3s;
      cursor: pointer;
      i{
        font-size: 20pt;
        color: #CCC;
        transition: 0.3s;
      }
      .namebtn{

      }
    }
    .pluspost:hover{
      border: 1px solid #454545;
      transition: 0.3s;
      i{
        color: #410099;
        transition: 0.3s;
      }
    }

  }
  .rightpath{
    width: 80px;
    margin-left: 20px;
  }
}
.menu{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .point{
    width: 260px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border: 1px solid #FFF;
    color: #454545;
    text-decoration: none;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.2s;
  }
  .point:hover{
    //box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    background-color: #f1fcf9;
    transition: 0.2s;

  }
}
.places{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  .places_line{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 20px;
    .places_line_radio{
      width: 200px;
      height: 300px;
      border: 1px solid #CCC;
      margin-right: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.2s;
    }
    .places_line_box{
      width: 500px;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      .places_line_box_square{
        width: 100%;
        height: calc(50% - 10px);
        border: 1px solid #CCC;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: 0.2s;
      }
    }
    .places_line_pr{
      width: 500px;
      height: 140px;
      margin-right: 20px;
      border: 1px solid #CCC;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.2s;
    }
    .places_line_more{
      width: 200px;
      height: 140px;
      border: 1px solid #CCC;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.2s;
    }

    .places_line_radio:hover, .places_line_box_square:hover, .places_line_more:hover, .places_line_pr:hover{
      background-color: #454545;
      transition: 0.2s;
      color: #FFF;
    }
  }
}

