@media screen and (min-width: 900px){
  .main{
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    animation: fadeInUp 0.6s forwards;
    width: 90%;
    height: calc(100% + 100px );
    background-color: #FFFFFF;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: -100px;
    z-index: 100001;

    .conatiner{
      width: 100%;
      //height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .title{
        font-size: 24pt;
        font-weight: 600;
        width: 80%;
        max-width: 800px;
        margin-bottom: 20px;
        margin-top: 50px;
        background-color: #410099;
        color: #FFF;
        margin-left: -14%;
        padding: 10px 50px 10px 100px;
      }
      .image{
        width: 100%;
        max-width: 800px;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          //width: 100%;
          max-width: 800px;
          margin-bottom: 20px;
          max-height: 90vh;
        }
      }
      .content{
        width: 100%;
        max-width: 800px;
        white-space: pre-wrap;
        p{
          margin-bottom: 20px;
          white-space: pre-wrap;
        }

        padding-bottom: 200px;
      }

    }

    .logo{
      width: calc(100% - 20px);
      padding: 0 0 10px 20px;
      img{
        width: 200px;
        margin-bottom: 10px;
      }
    }
  }
}
@media screen and (max-width: 900px){
  .main{
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    animation: fadeInUp 0.6s forwards;
    width: 100%;
    height: calc(100%);
    background-color: #FFFFFF;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 0px;
    z-index: 100001;

    .conatiner{
      width: 100%;
      //height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .title{
        font-size: 16pt;
        font-weight: 600;
        width: calc(100% - 40px);
        max-width: 800px;
        margin-bottom: 20px;
        margin-top: 0px;
        background-color: #410099;
        color: #FFF;
        //margin-left: -14%;
        padding: 10px 20px 10px 20px;
      }
      .image{
        width: calc(100%);
        max-width: 800px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img{
          width: calc(100% - 1px);
          max-width: 800px;
          margin-bottom: 20px;
          max-height: 90vh;
        }
      }
      .content{
        width: calc(100% - 20px);
        max-width: 800px;
        padding: 0 10px;
        p{
          margin-bottom: 20px;
        }

        padding-bottom: 100px;
      }

    }

    .logo{
      width: calc(100% - 20px);
      padding: 0 0 10px 20px;
      img{
        width: 200px;
        margin-bottom: 10px;
      }
    }
  }
}


@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
