.main{
  z-index: 100;
  width: calc(90% - 40px);
  height: calc(90% - 40px);
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 20px;
  .title{
    margin-top: 20px;
    font-size: 20px;
    font-weight: 600;
  }
  .formplus{
    display: flex;
    justify-content: flex-start;
    width: calc(100%);
    height: 42px;

    .nameinput{
      width: 180px;
      height: 100%;
      border: 1px solid #CCC;
      outline: none;
      padding: 0 10px;
    }
    .descinput{
      width: calc(100% - 200px - 160px);
      height: 100%;
      border: 1px solid #CCC;
      margin: 0 9px;
      outline: none;
      padding: 0 10px;
    }
    .nameinput:focus{
      border: 1px solid #454545;
    }
    .descinput:focus{
      border: 1px solid #454545;
    }
    .btnplus{
      width: 120px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #CCC;
      transition: 0.2s;
      cursor: pointer;
    }
    .btnplus:hover{
      background-color: #454545;
      color: #FFF;
      transition: 0.2s;
    }

  }
  .list{
    width: 100%;
    height: calc(100% - 80px);
    overflow-y: auto;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 16px;
    .line{
      display: flex;
      justify-content: flex-start;
      width: calc(100%);
      min-height: 42px;
      .name{
        width: calc(200px - 20px);
        height: 100%;
        border: 1px solid #CCC;
        padding: 10px;
        background-color: #F1F1F1;
      }
      .desc{
        width: calc(100% - 200px - 164px);
        height: 100%;
        border: 1px solid #CCC;
        margin: 0 9px;
        padding: 10px;
        background-color: #F1F1F1;
      }
      .tools{
        display: flex;
        justify-content: flex-end;
        width: calc(120px - 20px);
        height: calc(100% + 2px);
        padding: 10px;
        background-color: #F1F1F1;
        i{
          color: #454545;
          font-size: 20pt;
          cursor: pointer;
        }
        i:hover{
          color: #CCC;
        }
      }
    }

  }
}