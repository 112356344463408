.bodymain{
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  background-color: #E1E1E1;
  .main{
    width: 100%;
    max-width: 1920px;
    height: calc(100% - 80px);
    display: flex;

  }
  .leftpath{
    width: 300px;
    height: 100%;
    margin-right: 20px;
  }
  .centerpath{
    width: calc(100% - 340px);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 20px;
    .nav{
      display: flex;
      .back{
        width: 250px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 40px;
        border: 1px solid #CCC;
        margin: 20px 10px 20px 0px;
        transition: 0.3s;
        cursor: pointer;
        color: #454545;
        i{
          font-size: 20pt;
          color: #CCC;
          transition: 0.3s;
        }
        .namebtn{

        }
      }
      .back:hover{
        border: 1px solid #454545;
        transition: 0.3s;
        i{
          color: #410099;
          transition: 0.3s;
        }
      }
    }
    .plusblockz{
      display: flex;
      justify-content: flex-start;

      .photo{
        width: 250px;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #CCC;
        background-size: cover;
        background-position: center center;
        cursor: pointer;
        .active{
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(255,255,255,0);
          transition: 0.2s;
          i{
            color: #454545;
            font-size: 20pt;
          }
        }
      }
      .photo:hover{


        .active{

          transition: 0.2s;
          background-color: rgba(255,255,255,0.5);
        }
      }
      .formplus{
        width: 250px;
        height: 250px;
        display: flex;
        flex-direction: column;
        border: 1px solid #CCC;
        margin-left: 10px;
        justify-content: flex-end;
        input{
          width: calc(100% - 20px);
          border: none;
          border-top: 1px solid #CCC;
          border-bottom: 1px solid #CCC;
          margin-top: 0px;
          height: 40px;
          padding: 0 10px;
          outline: none;
          text-transform: uppercase;
        }
        input:focus{

          border: none;
          border-top: 1px solid #454545;
          border-bottom: 1px solid #454545;
        }
        textarea{
          width: calc(100% - 20px);
          border: none;
          border-top: 1px solid #CCC;
          border-bottom: 1px solid #CCC;
          margin-top: 10px;
          height: 120px;
          padding: 0 10px;
          outline: none;
          resize: none;
        }
        textarea:focus{

          border: none;
          border-top: 1px solid #454545;
          border-bottom: 1px solid #454545;
        }

        .btnplus{
          width: 100%;
          height: 60px;
          border-top: 1px solid #CCC;
          margin-top: 10px;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.2s;
          text-transform: uppercase;
        }
        .btnplus:hover{
          background-color: #454545;
          color: #FFF;
          border-top: 1px solid #454545;
          transition: 0.2s;
        }
      }

      .listzones{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 80%;
        margin-top: 10px;
        .thiszone{
          width: 100%;
          height: 400px;
          background-size: cover;
          background-position: center center;
          margin-bottom: 10px;
          position: relative;
          .back{
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.7);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 0;
          }
          .texts{
            width: 80%;
            height: 200px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            top: 100px;
            left: 10%;
            z-index: 1;
            color: #FFF;
            .texts_name{
              font-size: 24pt;
            }
            .texts_desc{
              font-size: 14pt;
            }
          }
          .tumbler{
            width: 50px;
            height: 90%;
            position: absolute;
            top: 5%;
            right: 0px;
            z-index: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            input{
              width: 30px;
              padding: 0;
              text-align: center;
              max-width: 30px;
              height: 40px;
            }
            .line_del{
              width: 30px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top: 20px;
              i{
                color: #FFF;
                font-size: 20pt;
                cursor: pointer;
                transition: 0.2s;
              }
              i:hover{
                color: #CCC;
                transition: 0.2s;
              }
            }
          }
        }


      }

    }
    .room{
      display: flex;
      flex-direction: column;
      .nameroom{
        font-size: 20pt;
        margin-top: 20px;
        border-bottom: 1px solid #CCC;
      }
      .slides{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        margin-top: 20px;
        .slide{
          width: 250px;
          height: 450px;
          position: relative;

          margin: 0 10px 10px 0;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .image{
            width: 250px;
            height: 250px;
            background-position: center center;
            background-size: cover;
            margin: 0;
          }
          .name{
            width: 100%;
            height: 30px;
            //background-color: #121212;
            color: rgb(245, 222, 30);
            margin: 0;
            margin-top: -40px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16pt;
            font-weight: 600;
            text-shadow: 0px 0px 6px rgba(0,0,0,0.6);
          }
          .description{
            color: #FFF;
            width: 90%;
            height: calc(200px);
            padding: 5px 5%;
            text-align: center;
            font-size: 10pt;
            background-color: rgba(0,0,0,1);
            margin: 0;
          }
          i{
            position: absolute;
            top: 10px;
            right: 10px;
            color: #FFF;
            font-size: 16pt;
            cursor: pointer;
          }
          i:hover{
            color: #CCC;
          }
        }

      }
    }

  }
  .rightpath{
    width: 80px;
    margin-left: 20px;
  }
}
.menu{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .point{
    width: 260px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border: 1px solid #FFF;
    color: #454545;
    text-decoration: none;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.2s;
  }
  .point:hover{
    //box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    background-color: #f1fcf9;
    transition: 0.2s;

  }
}

.loading{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(43, 28, 86, 0.63);


  .indicate{
    border: 8px solid rgba(0, 0, 0, 0.1); /* Легкий внешний цвет */
    border-top: 8px solid #3498db; /* Цвет лоадера */
    border-radius: 50%; /* Делает круг */
    width: 50px; /* Ширина лоадера */
    height: 50px; /* Высота лоадера */
    animation: spin 1s linear infinite; /* Анимация вращения */
    margin: 0 auto; /* Центрирование элемента */
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg); /* Начальная точка */
    }
    100% {
      transform: rotate(360deg); /* Конечная точка */
    }
  }

}
