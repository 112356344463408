.main{
  z-index: 100;
  width: 90%;
  height: 90%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  .plus{
    width: 80%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    .title{
      font-size: 14pt;
      font-weight: 600;
      width: calc(100% / 5 - 10px);
      height: 60px;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      border-bottom: 1px solid #CCC;
    }
    .name{
      font-size: 12pt;
      width: calc(100% / 5 - 30px);
      height: 60px;
      border: 1px solid #CCC;
      outline: none;
      padding: 0 10px;
    }
    .desc{
      font-size: 12pt;
      width: calc(100% / 5 - 30px);
      height: 60px;
      border: 1px solid #CCC;
      outline: none;
      padding: 0 10px;
    }
    .url{
      font-size: 12pt;
      width: calc(100% / 5 - 30px);
      height: 60px;
      border: 1px solid #CCC;
      outline: none;
      padding: 0 10px;
    }
    .btnplus{
      font-size: 12pt;
      width: calc(100% / 5 - 30px);
      max-width: 200px;
      height: 60px;
      border: 1px solid #CCC;
      outline: none;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: 0.2s;
    }

    .btnplus:hover{
      background-color: #454545;
      transition: 0.2s;
      color: #FFF;
    }
  }
  .list{
    width: 80%;
    height: calc(100% - 160px);
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .line{
      width: calc(100% - 43px);
      padding: 0 20px;
      height: 80px;
      min-height: 80px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #CCC;
      .line_name{
        width: calc(100% / 4 - 10px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .line_desc{
        width: calc(100% / 3 - 10px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .line_url{
        width: calc(100% / 3 - 10px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      .del{
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        i{
          font-size: 16pt;
          color: #CCC;
          cursor: pointer;
          transition: 0.2s;
        }

        i:hover{
          color: #454545;
          transition: 0.2s;
        }
      }
    }

  }
  .titleline{
    width: 80%;
    height: 60px;
    min-height: 59px;
    overflow-x: hidden;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .line{
      width: calc(100% - 43px);
      padding: 0 20px;
      height: 80px;
      min-height: 80px;
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid #CCC;
      font-weight: 600;
      .line_name{
        width: calc(100% / 4 - 10px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 600;
      }
      .line_desc{
        width: calc(100% / 3 - 10px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 600;
      }
      .line_url{
        width: calc(100% / 3 - 10px);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-weight: 600;
      }
      .del{
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }

  }
}