.bodymain{
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  background-color: #E1E1E1;
  .main{
    width: 100%;
    max-width: 1920px;
    height: calc(100% - 80px);
    display: flex;

  }
  .leftpath{
    width: 300px;
    height: 100%;
    margin-right: 20px;
  }
  .centerpath{
    width: calc(100% - 340px);
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 20px;
    .nav{
      display: flex;
      .back{
        width: 250px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 40px;
        border: 1px solid #CCC;
        margin: 20px 10px 20px 0px;
        transition: 0.3s;
        cursor: pointer;
        color: #454545;
        i{
          font-size: 20pt;
          color: #CCC;
          transition: 0.3s;
        }
        .namebtn{

        }
      }
      .back:hover{
        border: 1px solid #454545;
        transition: 0.3s;
        i{
          color: #410099;
          transition: 0.3s;
        }
      }
    }
    .pluspost{
      width: 250px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 40px;
      border: 1px solid #CCC;
      margin: 20px 0;
      transition: 0.3s;
      cursor: pointer;
      i{
        font-size: 20pt;
        color: #CCC;
        transition: 0.3s;
      }
      .namebtn{

      }
    }
    .pluspost:hover{
      border: 1px solid #454545;
      transition: 0.3s;
      i{
        color: #410099;
        transition: 0.3s;
      }
    }

  }
  .rightpath{
    width: 80px;
    margin-left: 20px;
  }
}
.menu{
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  .point{
    width: 260px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    border: 1px solid #FFF;
    color: #454545;
    text-decoration: none;
    margin-bottom: 10px;
    cursor: pointer;
    transition: 0.2s;
  }
  .point:hover{
    //box-shadow: 2px 2px 4px rgba(0,0,0,0.2);
    background-color: #f1fcf9;
    transition: 0.2s;

  }
}
.packs{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  .title{
    //margin-top: 20px;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #CCC;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 10pt;
    font-weight: 600;
    position: sticky;
    top: 0px;
    background-color: #D9D9D9;
    padding: 5px 0;
    .title_num{
      width: 40px;
      font-weight: 600;
      display: flex;
      justify-content: center;
    }
    .title_name{
      width: 150px;
      font-weight: 600;
      display: flex;
      justify-content: center;
    }
    .title_time{
      width: 150px;
      font-weight: 600;
      display: flex;
      justify-content: center;
    }
    .title_price{
      width: 100px;
      font-weight: 600;
      display: flex;
      justify-content: center;
    }
    .title_desc{
      width: calc(100% - 400px - 200px);
      font-weight: 600;
      display: flex;
      justify-content: center;
    }
    .title_priory{
      width: 80px;
      font-weight: 600;
    }
    .title_multi{
      width: 40px;
      font-weight: 600;
    }
  }
  .line{
    width: calc(100% - 2px);
    min-height: content-box;
    border-bottom: 1px solid #CCC;
    border-right: 1px solid #CCC;
    border-left: 1px solid #CCC;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 10pt;
    padding: 10px 0 0 0;
    .line_num{
      width: 29px;
      border-right: 1px solid #CCC;
      height: 100%;
      padding: 5px;
    }
    .line_name{
      width: 139px;
      border-right: 1px solid #CCC;
      height: 100%;
      padding: 5px;
    }
    .line_time{
      width: 139px;
      border-right: 1px solid #CCC;
      height: 100%;
      padding: 5px;
    }
    .line_price{
      width: 89px;
      border-right: 1px solid #CCC;
      height: 100%;
      padding: 5px;
    }
    .line_desc{
      width: calc(100% - 400px - 211px);
      border-right: 1px solid #CCC;
      height: 100%;
      padding: 5px;
      .line_desc_param{}
    }
    .line_priory{
      width: 69px;
      height: 100%;
      padding: 5px;

      input{
        width: 40px;
        text-align: center;
        height: 60px;
        border: 1px solid #CCC;
        outline: none;
      }
    }
    .line_multi{
      width: 30px;
      height: 100%;
      padding: 5px;
      i{
        color: #CCC;
        font-size: 16pt;
        cursor: pointer;
        transition: 0.2s;
        margin-bottom: 10px;
      }
      i:hover{
        color: #454545;
        transition: 0.2s;
      }
    }
  }
}

