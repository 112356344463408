.main{
  z-index: 100;
  width: 90%;
  height: 90%;
  background-color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  .left_path{
    width: 40%;
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 14pt;
    border-right: 1px solid #CCC;
    .photo{
      width: calc(1920px / 4 - 50px);
      height: calc(1920px / 3 - 50px);
      max-width: 300px;
      max-height: 400px;
      background-image: url("./imgs/avatar.png");
      background-size: cover;
      background-position: center center;
      margin: 10px 10px 10px 0px;
      position: relative;
      .active{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255,255,255,0.5);
        border: 1px solid #454545;
        i{
          font-size: 20pt;
          color: #CCC;
        }
      }
    }
    div{
      font-weight: 600;
      margin-top: 10px;
    }
    .title{
      font-size: 18pt;
      border-bottom: 1px solid #CCC;
    }
    .name{}
    .time{}
    .price{}
    .priory{}
    input{
      width: calc(100% - 40px);
      height: 50px;
      padding: 0 10px;
      outline: none;
      border: 1px solid #CCC;
    }
    textarea{
      width: calc(100% - 40px);
      height: 180px;
      outline: none;
      border: 1px solid #CCC;
      padding: 10px;
      resize: none;
    }
  }
  .right_path{
    width: calc(40% - 20px);
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 20px;
    font-size: 14pt;
    .title{
      font-weight: 600;
      margin-top: 32px;
      margin-bottom: 20px;
    }
    .desc{
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .desc_plus{
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        margin-bottom: 20px;
        input{
          width: calc(100% - 220px);
          height: 50px;
          padding: 0 10px;
          outline: none;
          border: 1px solid #CCC;
        }
        .plusdesc_btn{
          width: 186px;
          height: 50px;
          border: 1px solid #CCC;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 20px;
          transition: 0.2s;
          cursor: pointer;
        }
        .plusdesc_btn:hover{
          background-color: #454545;
          color: #FFF;
          transition: 0.2s;
        }
      }
      .list{

        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .line{
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 5px 0;
          border-bottom: 1px solid #CCC;
          margin-top: 5px;

          i{
            color: #CCC;
            cursor: pointer;
            transition: 0.2s;
            margin-right: 10px;
          }
          i:hover{
            color: #454545;
            transition: 0.2s;
          }
        }
      }

    }
  }
  .line_btn{
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 20px 40px;
    border: 1px solid #CCC;
    cursor: pointer;
    transition: 0.2s;
  }

  .line_btn:hover{
    background-color: #454545;
    color: #FFF;
    transition: 0.2s;
  }
}