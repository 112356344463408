.main{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
  background-color: #FFFFFF;
  z-index: 100;
  .text{}
  .btns{
    display: flex;
    .cancel{
      margin: 10px;
      border: 1px solid #454545;
      padding: 10px 30px;
      cursor: pointer;
      transition: 0.2s;
    }
    .delete{
      margin: 10px;
      border: 1px solid #d9122e;
      padding: 10px 30px;
      transition: 0.2s;
      cursor: pointer;
    }
    .cancel:hover{
      background-color: #410099;
      color: #FFFFFF;
      border: 1px solid #410099;
      transition: 0.2s;
    }
    .delete:hover{
      background-color: #d9122e;
      color: #FFFFFF;
      border: 1px solid #d9122e;
      transition: 0.2s;
    }
  }
}
