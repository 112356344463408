.main{
  width: 100%;
  height: 100%;
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  .point{
    width: calc(100% - 40px);
    height: 30px;
    display: flex;
    align-items: center;
    padding: 20px;
    border-bottom: 1px solid #CCC;
    cursor: pointer;
    position: relative;
    transition: 0.3s;
    text-decoration: none;
    color: #454545;
    i{
      font-size: 20pt;
      color: #CCC;
      transition: 0.3s;
    }
    .name{
      margin-left: 20px;
    }
    .active{
      width: 100%;
      height: 5px;
      background-color: #410099;
      position: absolute;
      bottom: 0px;
      left: 0px;
      transition: 0.3s;
      opacity: 0;
    }
  }
  .point:hover{
    transition: 0.3s;
    i{
      color: #410099;
      transition: 0.3s;
    }
    .active{
      opacity: 1;
      transition: 0.3s;
    }
  }
}