.main{
  z-index: 100;
  width: 90%;
  height: 90%;
  background-color: #FFFFFF;
  .title{
    width: 100%;
    height: 80px;
    font-weight: 800;
    font-size: 20pt;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #454545;
  }
  .form{
    width: 100%;
    height: calc(100% - 100px);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    .photo{
      width: calc(1920px / 4 - 50px);
      height: calc(1920px / 3 - 50px);
      max-width: 300px;
      max-height: 400px;
      background-image: url("./imgs/avatar.png");
      background-size: cover;
      background-position: center center;
      margin: 10px 10px 10px 10px;
      position: relative;
      .active{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255,255,255,0.5);
        border: 1px solid #454545;
        i{
          font-size: 20pt;
          color: #CCC;
        }
      }
    }
    .data{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: calc(1920px / 3 - 50px);
      max-height: 400px;
      margin: 10px 10px 10px 10px;
      .inputs{
        outline: none;
        border: 1px solid #CCC;
        width: 400px;
        height: 50px;
        padding: 0 10px;
        margin-bottom: 10px;
      }
      .btn{
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #454545;
        width: 400px;
        height: 60px;
        padding: 0 10px;
        cursor: pointer;
      }
      .btn:hover{
        color: #FFF;
        background-color: #410099;
        border: 1px solid #410099;
      }
    }

  }
}
