.main{
  width: calc(100% - 80px);
  //min-height: 300px;
  padding: 30px;
  border: 1px solid #CCC;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  .images{
    width: 400px;
    height: 300px;
    background-size: cover;
    background-position: center center;
    border-radius: 3px;
    cursor: pointer;
  }
  .images:hover{
    opacity: 0.9;
  }
  .desc{
    width: calc(100% - 500px);
    max-height: 400px;
    display: flex;
    flex-direction: column;
    .title{
      font-size: 20pt;
      font-weight: 600;
    }
    .description{
      font-size: 16pt;
      margin-bottom: 10px;
    }
    .text{
      font-size: 14pt;
    }
    .content{
      white-space: pre-wrap;
      max-height: 100%;
      overflow-y: hidden;
    }
  }
  .settings{
    i{
      font-size: 20pt;
      color: #CCC;
      cursor: pointer;
    }
    i:hover{
      color: #410099;
    }
  }
}