.main{
  z-index: 100;
  width: 90%;
  height: 90%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;

  .set{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }

  .vid{
    width: calc(50% - 40px);
    height: content-box;
    margin: 20px 20px 0 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    .boxone{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 0 15px;
      .one{
        width: 90px;
        height: 90px;
        border: 1px solid #CCC;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        img{
          width: 100%;
        }
      }
      .one:hover{
        border: 1px solid #454545;
        
      }

    }
    .datazone{
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      height: 100px;
      margin: 0;
      margin-top: 15px;
      .line{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        margin-top: 20px;
        .title{
          width: 140px;
          margin: 0;
          border: none;
          font-size: 12pt;
          font-weight: 400;
          display: flex;
          justify-content: center;
        }
        input{
          width: 400px;
          height: 40px;
          padding: 0 10px;
          margin: 0;
          border: 1px solid #CCC;
          outline: none;
        }
        textarea{
          width: 600px;
          padding: 0 10px;
          height: 310px;
          border: 1px solid #CCC;
          outline: none;
          resize: none;
        }
      }
    }

  }

  .editwindow{
    display: flex;
    flex-direction: column;
    width: calc(50% - 40px);
    margin: 10px 20px;
    .topstrock{
      display: flex;
      margin-bottom: 20px;
      .mainimg{
        width: 250px;
        height: 350px;
        margin-right: 20px;
        input{
          width: 100%;
          border: 1px solid #CCC;
          outline: none;
        }
      }
      .rightimg{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 250px;
        height: 350px;
        .firstimg{
          width: 250px;
          height: calc(350px / 2 - 10px);
          input{
            width: 100%;
            border: 1px solid #CCC;
            outline: none;
          }
        }
        .secondimg{
          width: 250px;
          height: calc(350px / 2 - 10px);
          input{
            width: 100%;
            border: 1px solid #CCC;
            outline: none;
          }
        }
      }
    }
    .bottomstrock{
      display: flex;
      justify-content: space-between;
      width: 524px;
      .lastimg{
        width: calc(100% / 3 - 10px);
        height: 150px;
        input{
          width: 100%;
          border: 1px solid #CCC;
          outline: none;
        }
      }
    }

  }

    .photo{
      width: 100%;
      height: calc(100% - 30px);
      max-width: 300px;
      max-height: 400px;
      background-image: url("./imgs/avatar.png");
      background-size: cover;
      background-position: center center;
      margin: 10px 10px 10px 0px;
      position: relative;
      .active{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255,255,255,0.5);
        border: 1px solid #454545;
        i{
          font-size: 20pt;
          color: #CCC;
        }
      }
    }

  .line_btn{
    position: absolute;
    bottom: 20px;
    right: 20px;
    padding: 20px 40px;
    border: 1px solid #CCC;
    cursor: pointer;
    transition: 0.2s;
  }

  .line_btn:hover{
    background-color: #454545;
    color: #FFF;
    transition: 0.2s;
  }
}
.loading{
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(43, 28, 86, 0.63);


  .indicate{
    border: 8px solid rgba(0, 0, 0, 0.1); /* Легкий внешний цвет */
    border-top: 8px solid #3498db; /* Цвет лоадера */
    border-radius: 50%; /* Делает круг */
    width: 50px; /* Ширина лоадера */
    height: 50px; /* Высота лоадера */
    animation: spin 1s linear infinite; /* Анимация вращения */
    margin: 0 auto; /* Центрирование элемента */
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg); /* Начальная точка */
    }
    100% {
      transform: rotate(360deg); /* Конечная точка */
    }
  }

}